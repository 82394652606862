import React, {Component} from 'react';
import Section from '../section';

class AGB extends Component {
  render() {
    return (
      <div>
        <div className="ot-section">
          <div className="ot-section__container">
            <div className="ot-section__headline">
              <h2>Allgemeine Geschäftsbedingungen</h2>
            </div>
            <div className="ot-text">
              <p>
                <strong>Präambel</strong>
              </p>
              <p>
                <strong>OffTogether UG (haftungsbeschränkt)</strong>,
                Lichtenstraße 7a, 37327 Wingerode (nachfolgend auch:
                „OffTogether“), bietet in der mobilen App{' '}
                <strong>OffTogether</strong>
                (nachfolgend „OffTogether-Plattform“) eine Plattform an, die
                Nutzern ermöglicht, sich kennenzulernen, Aktivitäten zu
                erstellen und zu planen, Gruppen zu gründen und Gruppen
                beizutreten sowie Turniere zu erstellen und daran teilzunehmen.
              </p>
              <p>
                Über die OffTogether-Plattform können diverse Dienstleistungen
                im Bereich der Sport- und Freizeitgestaltung (gemeinsam das
                „Sport- und Freizeitangebot“) gesucht, gebucht („Buchung“) und
                online bezahlt werden. Das Sport- und Freizeitangebot wird
                entweder (i) vom jeweiligen Partnerunternehmen („Betreiber“) in
                Eigenverantwortung in eigenem Namen und auf eigene Rechnung
                durchgeführt oder (ii) einem anderen Nutzer (wie in Punkt 1.3 a)
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AGB;
