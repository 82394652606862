import React, {Component} from 'react';
import '../styles/03-components/footer.scss';
import {Link} from 'react-router-dom';

class Footer extends Component {
  render() {
    return (
      <div className="ot-footer">
        <div className="ot-footer__container">
          <div className="ot-footer__title">
            <h1>
              Off<span>Together</span>
            </h1>
          </div>
          <ul className="ot-footer__list">
            <li className="ot-footer__list-item">
              <h2>Kontakt</h2>
              <ul className="ot-footer__list">
                <li className="ot-footer__list-item">
                  <div className="ot-footer__item">
                    <a className="ot-footer__link" href="/#">
                      Über uns
                    </a>
                  </div>
                </li>
                <li className="ot-footer__list-item">
                  <div className="ot-footer__item">
                    <a className="ot-footer__link" href="/#">
                      Datenschutzrichtlinien
                    </a>
                  </div>
                </li>
                <li className="ot-footer__list-item">
                  <div className="ot-footer__item">
                    <Link to="/agbs" className="ot-footer__link">
                      AGBs
                    </Link>
                  </div>
                </li>
              </ul>
            </li>
            <li className="ot-footer__list-item">
              <h2>OffTogether UG</h2>
              <ul className="ot-footer__list">
                <li className="ot-footer__list-item">
                  <div className="ot-footer__item">
                    <a className="ot-footer__link" href="/#">
                      Lichtenstraße 7a
                    </a>
                  </div>
                </li>
                <li className="ot-footer__list-item">
                  <div className="ot-footer__item">
                    <a className="ot-footer__link" href="/#">
                      37327 Wingerode
                    </a>
                  </div>
                </li>
                <li className="ot-footer__list-item">
                  <div className="ot-footer__item">
                    <a className="ot-footer__link" href="/#">
                      Tel.: 0151 12345678
                    </a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Footer;
