import './App.css';
import Footer from './views/footer';
import Header from './views/header';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './views/pages/Home';
import AGB from './views/pages/AGB';

function App() {
  return (
    <Router>
      <div className="renderPage">
        <Header />
        <div className="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/agbs" element={<AGB />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
