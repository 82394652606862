import React, {Component} from 'react';
import '../styles/03-components/section.scss';
import ContentBlock from './content-block';

class Section extends Component {
  render() {
    return (
      <div className="ot-section">
        <div className="ot-section__container">
          <div className="ot-section__headline">
            <h1>Triff dich offline: Sport verbindet!</h1>
          </div>
          <div className="ot-section__description">
            <p>
              Egal ob Fußball, Yoga oder Wandern – entdecke Aktivitäten, knüpfe
              Kontakte und werde Teil einer sportlichen Community.
            </p>
          </div>
          <div className="ot-section__content">
            <ContentBlock
              title="Erstelle Aktivitäten"
              text="Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin.
              Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin."
              iconSrc="/icons/heart-beat.png"
            />
            <ContentBlock
              title="Gruppen gründen"
              text="Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin.
              Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin."
              iconSrc="/icons/group.png" // Optional für ein anderes Icon
            />
            <ContentBlock
              title="Messe dich mit Freunden"
              text="Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin.
              Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin. Hier kommt ein Bsopieltext hin."
              iconSrc="/icons/award.png"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Section;
