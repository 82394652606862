import React, {Component} from 'react';
import '../styles/03-components/content-block.scss';

class ContentBlock extends Component {
  render() {
    const {title, text, iconSrc} = this.props;
    return (
      <div className="ot-content-block">
        <div className="ot-content-block__container">
          <div className="ot-content-image">
            <span className="icon">
              {iconSrc && (
                <img src={iconSrc} alt={title} width="42" height="42" />
              )}
            </span>
          </div>
          <div className="ot-content-title">
            <h2>{title}</h2>
          </div>
          <div className="ot-content-text">
            <p>{text}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ContentBlock;
